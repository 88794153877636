$background-layer: -1;
$column-gap: 2rem;
$small-column-gap: 1rem;
$row-gap: 2rem;

@function column-width($gap) {
  @return calc(50% - ($gap / 2));
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  background-image: url("../static/ai-background-70-percent.png");
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  z-index: $background-layer;
}

.foreground {
  padding: 3rem;
}

.home-window {
  margin: auto;
  width: 80%;

  &__text {
    padding: 1rem;
    background: linear-gradient(
      to right,
      lighten(blue, 30%),
      lighten(blue, 10%)
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "SixtyFour", sans-serif;
    font-size: 3rem;
    text-align: center;

    @include respond(medium) {
      font-size: 2.1rem;
    }

    @include respond(small) {
      font-size: 2rem;
    }
  }
}

.content {
  height: 100%;
  padding-top: $row-gap;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $column-gap;

  @media (max-width: 666px) {
    width: 100%;
    flex-direction: column;
    gap: $row-gap;
  }

  &__column {
    display: none;

    @media (min-width: 667px) {
      width: column-width($column-gap);
      display: flex;
      flex-direction: column;
      gap: $row-gap;
    } 
  }
}

.about-window {
  order: 1;
}

.open-source-sudoku-window {
  order: 1;

  &__images {
    order: 2;

    padding: 0 1rem 1rem 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $small-column-gap;

    & img {
      width: calc(50% - $column-gap);
      background: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.7),
          rgba(0, 0, 0, 0.8)
        )
        border-box;
      border-radius: 0.5em;
      border: 0.5rem solid transparent;
      outline: 1rem solid
        linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8));
      -webkit--box-shadow: 0 0 0 1rem
        linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8));
    }
  }
}

.emulator-window {
  order: 2;
}

.indexable-str-window {
  order: 3;
}
