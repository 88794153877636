@font-face {
  font-family: "Roboto";
  src: url("../static/fonts/Roboto-Regular.woff2");
}

@font-face {
  font-family: "Workbench";
  src: url("../static/fonts/Workbench-Regular.woff2");
}

@font-face {
  font-family: "SixtyFour";
  src: url("../static/fonts/Sixtyfour-Regular.woff2");
}