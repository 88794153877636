*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

@media (width >= 64em) {
  html {
    font-size: 68.75%;
  }
}

@media (width <= 48em) {
  html {
    font-size: 56.25%;
  }
}

@media (width <= 41.625em) {
  html {
    font-size: 50%;
  }
}

@media (width <= 35.5em) {
  html {
    font-size: 43.75%;
  }
}

@media (width <= 22.5em) {
  html {
    font-size: 37.5%;
  }
}

body {
  box-sizing: border-box;
  color: #000000b3;
  min-width: 100vw;
  min-height: 100vh;
  font-family: Roboto, sans-serif;
  font-size: 1.6rem;
  font-style: normal;
  overflow-x: hidden;
}

a:link, a:visited, a:hover, a:active {
  color: #66f;
  text-decoration: none;
}

a:active {
  color: #99fc;
}

a[target="_blank"]:after {
  content: "";
  background-image: url("open_in_new_normal.823d1809.svg");
  background-size: cover;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
  transform: translateY(.2rem);
}

a[target="_blank"]:active:after {
  background-image: url("open_in_new_active.bac162a9.svg");
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Regular.63f26da3.woff2");
}

@font-face {
  font-family: Workbench;
  src: url("Workbench-Regular.6c2f37c9.woff2");
}

@font-face {
  font-family: SixtyFour;
  src: url("Sixtyfour-Regular.733045b6.woff2");
}

.bold {
  font-weight: bold;
}

.background {
  z-index: -1;
  background-image: url("ai-background-70-percent.f966dbf0.png");
  background-position: center;
  background-size: cover;
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.foreground {
  padding: 3rem;
}

.home-window {
  width: 80%;
  margin: auto;
}

.home-window__text {
  background: linear-gradient(to right, #99f, #33f);
  -webkit-text-fill-color: transparent;
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  padding: 1rem;
  font-family: SixtyFour, sans-serif;
  font-size: 3rem;
}

@media (width <= 41.625em) {
  .home-window__text {
    font-size: 2.1rem;
  }
}

@media (width <= 35.5em) {
  .home-window__text {
    font-size: 2rem;
  }
}

.content {
  flex-flow: wrap;
  gap: 2rem;
  height: 100%;
  padding-top: 2rem;
  display: flex;
}

@media (width <= 666px) {
  .content {
    flex-direction: column;
    gap: 2rem;
    width: 100%;
  }
}

.content__column {
  display: none;
}

@media (width >= 667px) {
  .content__column {
    flex-direction: column;
    gap: 2rem;
    width: calc(50% - 1rem);
    display: flex;
  }
}

.about-window, .open-source-sudoku-window {
  order: 1;
}

.open-source-sudoku-window__images {
  flex-flow: wrap;
  order: 2;
  gap: 1rem;
  padding: 0 1rem 1rem;
  display: flex;
}

.open-source-sudoku-window__images img {
  outline: 1rem solid linear-gradient(to right, #000000b3, #000c);
  -webkit--box-shadow: 0 0 0 1rem linear-gradient(to right, #000000b3, #000c);
  background: linear-gradient(to right, #000000b3, #000c) border-box;
  border: .5rem solid #0000;
  border-radius: .5em;
  width: calc(50% - 2rem);
}

.emulator-window {
  order: 2;
}

.indexable-str-window {
  order: 3;
}

/*# sourceMappingURL=index.b6f6f1cd.css.map */
