$primary-text-color: rgba(0, 0, 0, .7);
$hyperlink-active-color: rgba(lighten(blue, 30%), .8);
$hyperlink-color: rgba(lighten(blue, 20%), 1.0);

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
  font-size: 62.5%; // 1rem = 10px

  @include respond(x-large) {
      font-size: 68.75%;          // 1rem = 11px
  }

//   @include respond(xx-large) {
//       font-size: 75%;             // 1rem = 12px
//   }

  @include respond(large) {
      font-size: 56.25%;          // 1rem = 9px
  }

  @include respond(medium) {
      font-size: 50%;             // 1rem = 8px
  }

  @include respond(small) {
      font-size: 43.75%;          // 1rem = 7px
  }

  @include respond(x-small) {
      font-size: 37.5%;           // 1rem = 6px
  }
}

body {
  box-sizing: border-box;
  min-height: 100vh;
  min-width: 100vw;   
  color: $primary-text-color;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 1.6rem;
  overflow-x: hidden;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: $hyperlink-color;
}

a:active {
  color: $hyperlink-active-color;
}

a[target="_blank"]::after {
  content: '';
  display: inline-flex;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url('../static/icons/open_in_new_normal.svg');
  background-size: cover;
  transform: translateY(.2rem);
}

a[target="_blank"]:active::after {
  background-image: url('../static/icons/open_in_new_active.svg');
}
